import "core-js/modules/es.array.push.js";
import store from '@/state/store.js';
export default {
  data() {
    return {
      query: null
    };
  },
  methods: {
    open(openRoute) {
      if (this.$route.path == openRoute) return;
      this.$router.push(openRoute).catch(err => {
        if (err.name !== "NavigationDuplicated") throw err;
      });
    },
    logout() {
      store.dispatch('auth/logout').then(res => {
        this.$router.push('/signin').catch(err => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      });
    }
  }
};