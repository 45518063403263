import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "__help_page"
  }, [_c('div', {
    staticClass: "help__header"
  }, [_c('div', {
    staticClass: "__header_nav section_padding"
  }, [_vm._m(0), _c('div', {
    staticClass: "right__nav"
  }, [_c('el-dropdown', [_c('el-button', {
    attrs: {
      "id": "profile"
    }
  }, [_vm._v(" Dropdown List"), _c('i', {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', [_c('button', {
    staticClass: "_btn",
    on: {
      "click": function ($event) {
        _vm.$router.push('/overview').catch(err => {
          if (err.name !== 'NavigationDuplicated') throw err;
        });
      }
    }
  }, [_vm._v("Dashboard")])]), _c('el-dropdown-item', [_c('button', {
    staticClass: "_btn",
    on: {
      "click": function ($event) {
        _vm.$router.push('/profile').catch(err => {
          if (err.name !== 'NavigationDuplicated') throw err;
        });
      }
    }
  }, [_vm._v("Profile")])]), _c('el-dropdown-item', [_c('button', {
    staticClass: "_btn",
    on: {
      "click": function ($event) {
        _vm.$router.push('developer').catch(err => {
          if (err.name !== 'NavigationDuplicated') throw err;
        });
      }
    }
  }, [_vm._v(" Developer ")])]), _c('el-dropdown-item', [_c('button', {
    staticClass: "_btn",
    on: {
      "click": _vm.logout
    }
  }, [_vm._v(" Logout ")])])], 1)], 1)], 1)]), _c('div', {
    staticClass: "header_section"
  }, [_c('div', {
    staticClass: "help_header_sections"
  }, [_c('div', {
    staticClass: "help_header_inner_section"
  }, [_c('h2', [_vm._v(" Hello, how can we help? ")]), _c('p', [_vm._v(" Urna quis nulla vitae, condimentum varius in adipiscing blandit. Vitae sodales id feugiat parturient venenatis. ")]), _c('div', {
    staticClass: "_search_bar_container"
  }, [_c('el-input', {
    attrs: {
      "id": "search_bar",
      "placeholder": "Search"
    },
    model: {
      value: _vm.query,
      callback: function ($$v) {
        _vm.query = $$v;
      },
      expression: "query"
    }
  }, [_c('i', {
    staticClass: "el-input__icon el-icon-search",
    attrs: {
      "slot": "prefix"
    },
    slot: "prefix"
  })])], 1)])])])]), _c('div', {
    staticClass: "__help_main_section section_padding"
  }, [_c('div', {
    staticClass: "_help_main_navigation"
  }, [_c('ul', [_c('li', {
    class: [_vm.$route.path == '/help/getting_started' ? '_help_list_active' : ''],
    on: {
      "click": function ($event) {
        return _vm.open('/help/getting_started');
      }
    }
  }, [_vm._v(" Getting started ")]), _c('li', {
    class: [_vm.$route.path == '/help/faqs' ? '_help_list_active' : ''],
    on: {
      "click": function ($event) {
        return _vm.open('/help/faqs');
      }
    }
  }, [_vm._v(" FAQ ")]), _c('li', {
    class: [_vm.$route.path == '/help/quick_messaging' ? '_help_list_active' : ''],
    on: {
      "click": function ($event) {
        return _vm.open('/help/quick_messaging');
      }
    }
  }, [_vm._v(" Quick messaging ")]), _c('li', {
    class: [_vm.$route.path == '/help/voice_messaging' ? '_help_list_active' : ''],
    on: {
      "click": function ($event) {
        return _vm.open('/help/voice_messaging');
      }
    }
  }, [_vm._v(" Voice messaging ")]), _c('li', {
    class: [_vm.$route.path == '/help/managing_contacts' ? '_help_list_active' : ''],
    on: {
      "click": function ($event) {
        return _vm.open('/help/managing_contacts');
      }
    }
  }, [_vm._v(" Managing contacts ")]), _c('li', {
    class: [_vm.$route.path == '/help/reports' ? '_help_list_active' : ''],
    on: {
      "click": function ($event) {
        return _vm.open('/help/reports');
      }
    }
  }, [_vm._v(" Reports ")])])]), _c('div', {
    staticClass: "help__main__sections_wrapper"
  }, [_c('router-view')], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "_logo"
  }, [_c('img', {
    attrs: {
      "height": "65px",
      "width": "100px",
      "src": require("../../assets/logo.png"),
      "alt": ""
    }
  })]);
}];
export { render, staticRenderFns };